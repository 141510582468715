@import "../../variables.scss";

.DeviceCard {
  height: 312px;

  > .topContainer {
    padding-top: 12px;
    padding-right: 4px;
    padding-left: 4px;

    > .imageGrid {
      padding-top: 8px;

      > .cardMedia {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        height: 130px;
        object-fit: contain;
      }
    }

    > .menuGrid {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-end;
    }
  }

  > .bottomContainer {
    padding-top: 20px;
    padding-right: 4px;
    padding-left: 4px;
    padding-bottom: 4px;

    > .content > .cardContent {
      padding: 0;

      > .title {
        color: $coc-secondary-2D;
      }

      > .information {
        color: $coc-secondary-3D;
      }
    }
  }
}
