@import "../../variables.scss";

.Modal {
  .modalWidth {
    min-width: 25vw;

    &.big {
      min-width: 90vw;
      min-height: 75vh;
      padding: 24px;
    }
  }

  .title {
    .dangerIcon {
      margin-right: 10px;
    }

    > .text {
      color: $coc-secondary-2D;
    }

    &.big {
      padding-left: 38px;
    }
  }

  .hiddenOverflowY {
    overflow-y: hidden;
  }

  .actions {
    padding-bottom: 1rem;
    padding-right: 1.5rem;

    > .cancelButton,
    > .confirmButton,
    > .confirmButtonDanger {
      font-size: 1rem;
    }

    > .cancelButton {
      background-color: $coc-secondary-10L;
      color: $coc-secondary-2D;

      &:hover {
        background-color: $coc-secondary-9L;
      }
    }

    > .confirmButton {
      background-color: $coc-secondary-8L;
      color: $coc-secondary-2D;

      &:hover {
        background-color: $coc-secondary-7L;
      }

      &.danger {
        background-color: $coc-main;
        color: $white;

        &:hover {
          background-color: $coc-secondary-7L;
          background-color: $coc-main-hover;
        }
      }

      &.Mui-disabled {
        opacity: 0.35;
      }
    }
  }
}
