@import "../../variables.scss";

.WebPasswordCard {
  height: 156px;

  .cardHeader {
    .headerTitle {
      display: grid;

      > .text {
        padding-right: 22px;
        color: $coc-secondary-2D;
      }
    }
  }

  .cardContent {
    padding-top: 0px;

    & .MuiFilledInput-root {
      border-radius: 16px;

      & > .MuiFilledInput-input {
        padding: 18px 20px;
      }
    }
  }
}
