$black: #000000;
$white: #ffffff;
$coc-main: #c8102e;
$coc-main-hover: #b6112d;
$coc-secondary: #4b4f55;
$coc-secondary-1L: #5d6066;
$coc-secondary-2L: #6f7277;
$coc-secondary-3L: #818388;
$coc-secondary-4L: #939599;
$coc-secondary-5L: #a5a7aa;
$coc-secondary-6L: #b7b9bb;
$coc-secondary-7L: #c9cacc;
$coc-secondary-8L: #dbdcdd;
$coc-secondary-9L: #ededee;
$coc-secondary-10L: #f6f6f6;
$coc-secondary-1D: #191919;
$coc-secondary-2D: #333333;
$coc-secondary-3D: #4c4c4c;
$coc-secondary-4D: #666666;
$coc-secondary-5D: #7f7f7f;
$coc-secondary-6D: #999999;
$coc-secondary-7D: #b2b2b2;
$coc-secondary-8D: #cccccc;
$coc-secondary-9D: #e5e5e5;
$coc-secondary-green: #4c8c2b;
