@import "../../variables.scss";

.SideMenuBottomVersion {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;

  > .text {
    font-size: 0.9rem;
    color: $coc-secondary-3D;
  }
}
