@import "./variables.scss";

html,
body {
  font-family: "Open Sans", Helvetica, Arial, Verdana, sans-serif;

  .customScrollbar {
    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: $coc-secondary-9L;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $coc-secondary-7L;
      border: 1px solid $coc-secondary-9L;
    }
  }

  .customTooltipDisplay {
    font-size: 1rem !important;
    max-width: 420px !important;
    padding: 16px 10px 16px 20px !important;
  }
}
