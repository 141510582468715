.SideMenuBottomLogos {
  width: 100%;

  > .sectionOne,
  > .sectionTwo {
    display: grid;
    grid-template-columns: 50% 50%;
    padding-bottom: 8px;

    > .item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      > .image {
        max-width: 52px;
      }

      > .imageHorizontal {
        max-width: 126px;
        padding-left: 24px;
      }
    }
  }

  > .sectionTwo {
    padding-top: 16px;
  }
}
