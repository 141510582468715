@import "../../variables.scss";

.SimulationCard {
  height: 200px;

  .cardMedia {
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    height: 180px;
    object-fit: contain;
  }

  .cardHeader {
    .headerTitle {
      display: grid;
      grid-template-columns: 65% 35%;

      > .text {
        padding-right: 22px;
        color: $coc-secondary-2D;
      }

      > .chips {
        display: flex;
        justify-content: center;

        > .vr,
        > .web {
          padding-left: 12px;
        }
      }

      > .chips > .web {
        margin-left: 6px;
      }
    }

    .simulationButtonAdd {
      color: $coc-secondary-green;
    }

    .simulationButtonRemove {
      color: $coc-main;
    }
  }

  .cardContent {
    padding-top: 0px;
    padding-right: 16px;

    > .description {
      color: $coc-secondary-3D;
      height: 114px;
      overflow: auto;
      padding-right: 12px;
    }
  }
}
