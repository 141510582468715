@import "../../variables.scss";

.FloatingActionButton {
  top: auto;
  right: 2rem;
  bottom: 2rem;
  left: auto;
  position: fixed;

  > .element {
    height: 4.5rem;
    width: 4.5rem;
    background-color: $coc-secondary-3D;
    color: $coc-secondary-10L;
    &:hover {
      background-color: $coc-secondary-4D;
      color: $coc-secondary-10L;
    }
  }
}
