@import "../../variables.scss";

.SimulationLinkModal {
  > .simulationsContainer {
    display: grid;
    grid-template-columns: 47% 6% 47%;

    > .leftContainer > .leftContainerHeader,
    > .rightContainer > .rightContainerHeader {
      display: flex;
      flex-direction: row;
      align-items: center;

      > .text {
        color: $coc-secondary-4D;

        .infoIcon {
          margin-left: 10px;
        }
      }
    }

    > .leftContainer > .simulationCardList,
    > .rightContainer > .simulationCardList {
      align-content: flex-start;
      height: 532px;
      overflow-y: auto;
      padding-top: 4px;
      padding-right: 16px;
      padding-bottom: 16px;
      margin-top: 4px;
    }

    > .leftContainer,
    > .rightContainer {
      display: flex;
      flex-direction: column;
      padding-left: 16px;
    }

    > .dividerContainer {
      display: flex;
      justify-content: center;

      > .divider {
        height: 400px;
        margin-top: 90px;
      }
    }
  }
}
