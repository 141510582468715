@import "../../variables.scss";

.InitialSection {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > .containerInteractionElements {
    display: flex;
    align-items: center;

    > .modeSelector {
      margin-right: 2rem;

      .nonDeletedSimulationsButton,
      .deletedSimulationsButton {
        border-radius: 4px;

        &:hover,
        &.selected {
          background-color: $coc-secondary-8D;
        }
      }
    }
  }
}
