.SideMenu {
  z-index: 1;
  width: 287px;
  flex-shrink: 0;

  .container {
    margin-top: 103px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  & .MuiDrawer-paper {
    width: 287px;
    box-sizing: border-box;
  }

  .list {
    margin-top: 103px;
    overflow: auto;
  }
}
