@import "../../variables.scss";

.CustomListItem {
  &.Mui-selected {
    background-color: $coc-secondary-8L !important;
  }

  > .icon {
    color: $coc-secondary-2D;
    padding: 1rem;
  }

  > .text > .MuiListItemText-primary {
    color: $coc-secondary-2D;
    font-size: 1rem;
  }
}
