@import "../../variables.scss";

.Navbar {
  z-index: 2 !important;
  background-color: $coc-secondary-10L !important;

  > .toolbar {
    > .logo {
      max-width: 217px;
      cursor: pointer;
    }

    > .text {
      font-size: 1.625rem;
      color: $coc-secondary-2D;
      flex-grow: 1;
    }

    > .userCircle {
      color: $coc-secondary-2D;

      .avatar {
        color: $coc-secondary-10L;
        background-color: $coc-secondary-4D;
      }
    }
  }
}
