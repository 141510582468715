@import "../../variables.scss";

.FileUploadingIndicator {
  z-index: 2;

  > .card {
    min-width: 300px;
    min-height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $coc-secondary-9D;

    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > .text {
        padding-bottom: 16px;
        text-transform: uppercase;
        color: $coc-secondary-2D;
      }
      .box {
        > .progress {
          color: $coc-secondary-2D;
        }
      }
    }
  }
}
